<template>
  <div class="d-flex justify-center align-center pa-5" :class="$vuetify.breakpoint.xs && 'flex-column'">
    <!-- <div class="">
      <v-text-field 
        dense 
        outlined 
        class="general-custom-field roboto f14 fw500"
        hide-details
        placeholder="Search posts, comments"
        prepend-inner-icon="mdi-magnify"
      />
    </div>
    <v-divider vertical class="mx-5"/> -->
    <v-row dense>
      <v-col cols="12" md="6" sm="6">
        <div class="d-flex align-center">
          <div class="mr-2 poppins secondary-1--text f13 fw500">Course</div>
            <v-select
            dense 
            outlined 
            class="general-custom-field roboto f14 fw500"
            hide-details
            :items="courses"
            item-text="title"
            item-value="id"
            v-model="selected_course"
            :loading="loading"
          />
        </div>
      </v-col>
      <v-col cols="12" md="6" sm="6">
        <div class="d-flex align-center">
          <div class="mr-2 poppins secondary-1--text f13 fw500">Date Added</div>
            <v-select
              v-model="selected_date_interval"
              dense 
              outlined 
              class="general-custom-field roboto f13 fw500"
              hide-details
              :items="items"
              ref="select"
            >
              <template v-slot:selection="data">
                <span class="roboto f13 fw500">{{ data.item === 'Go to' ? `Go to ${date}` : data.item }}</span>
              </template>
              <template v-slot:item="data">
              <div v-if="data.item === 'Go to'"  @click.stop
                  class="d-flex flex-row roboto f13 pt-1">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  bottom
                  min-width="auto"
                >
                <template v-slot:activator="{ on, attrs }">
                  <span class="mt-1">Go to: </span>
                  <v-text-field
                    v-model="date"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    class="ml-5"
                    dense
                    no-details
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="menu = false"
                  no-title
                  type="year"
                  active-picker="YEAR"
                ></v-date-picker>
              </v-menu>
                </div>
                <div v-else class="roboto f13 fw500">
                  {{ data.item }}
                </div>
              </template>
            </v-select>
        </div>
      </v-col>
    </v-row>
    <v-spacer/>
    <v-btn @click="$emit('createPost')" class="text-capitalize fw600 roboto py-3" color="primary" :block="$vuetify.breakpoint.xs"  :class="$vuetify.breakpoint.xs && 'mt-3'">
      <v-icon left>mdi-plus</v-icon>
      Post
    </v-btn>
  </div>
</template>

<script>
export default {
  props: ['courses', 'loading'],
  data: () => ({
    date: null,
    menu: false,
    post: 'Write Something...',
    selected_course: '',
    selected_date_interval: 'Today',
    items: [
      'Today',
      'This week', 
      'This month',
      'This year',
      'Go to'
    ]
  }),
  mounted() {
    if(this.$route.query.course) {
      this.selected_course = Number(this.$route.query.course)
    } 
    if (this.$route.query.interval) {
      if(!['Today','This week','This month','This year'].includes(this.$route.query.interval)){
        this.selected_date_interval = 'Go to'
        this.date = this.$route.query.interval
      } else {
        this.selected_date_interval = this.$route.query.interval
      }
    }
    if(!this.$route.query.course && !this.$route.query.interval) {
      this.$router.replace({ query: { course: this.selected_course, interval: this.selected_date_interval, timestamp: Date.now() } });
    }
  },
  watch: {
    selected_course(val) {
      if (val !== '') {
        this.$emit('setFilter', val)
      }
    },
    selected_date_interval(val) {
      if(val.includes('Go to')) {
        this.$emit('setDateInterval', `${this.date}`)
      } else {
        this.$emit('setDateInterval', val)
        this.date = null
      }
    },
    date(val){
      this.selected_date_interval = `Go to`
      this.$refs.select.blur()
    }
  }
}
</script>