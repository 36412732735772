<template>
  <section class="border custom-border">
    <v-sheet class="border">
      <Header 
        @createPost="dialog=true" 
        :courses = "courses" 
        :loading = "course_loading"
        @setFilter="setFilter" 
        @setDateInterval="setDateInterval" 
        v-if="!selected_announcement_id"/>
      <div v-else class="d-flex align-center pa-5">
        <v-btn 
          icon 
          @click="selected_announcement_id=null, selected_announcement_comment_id=null" 
          class="mr-2">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <div class="roboto f16 fw600 secondary-1--text my-2">Announcements</div>
      </div>
      
      <v-divider></v-divider>
      <v-progress-linear
        indeterminate
        color="primary"
        v-if="filter_loading || loading"
      ></v-progress-linear>
      
      <v-sheet v-if="announcements.length==0 && !filter_loading" class="border d-flex flex-column align-center justify-center pb-10 text-center" height="60vh">
        <div class="">
          <v-img 
            max-width="300" 
            contain
            :src="require('../../assets/default/empty_drafts.png')" />
        </div>
        <h3 class="poppins secondary-1--text fw600 my-5 text-center">You haven’t posted any announcements yet.</h3>
        <!-- <a 
          href="#" 
          @click="dialog=true" 
          class="text-decoration-underline f14 poppins primary--text"
        >
          Post Announcement
        </a> -->
      
      </v-sheet>
      <v-container v-else-if="announcements.length>0 && !filter_loading" min-height="60vh" max-height="60vh" color="grey lighten-5" class="border-bottom overflow-y-auto mb-5">
        <PostCard 
          v-for="item in announcements" 
          :id="`comment-${item.id}`"
          :key="item.id" 
          :item="item"
          :user="user"
          @remove="remove"
          :selected_announcement_id="selected_announcement_id"
          :selected_announcement_comment_id="selected_announcement_comment_id"
          @setAnnouncement="setAnnouncement"
          @updateAnnouncement="updateAnnouncement"
        />
      </v-container>
      
    </v-sheet>

    <PostDialog 
      :dialog="dialog" 
      :user="user"
      :courses="courses"
      @close="dialog=false"
      @post="posted"
    />
    <PostUpdateDialog
      :dialog="editDialog" 
      :user="user"
      :courses="courses"
      :announcement="selected_announcement"
      @close="editDialog=false"
    />

    <!-- <toaster :show="saved" @close="saved=false" :text="text"/> -->
  
    <delete-toast 
      :dialog="deleteDialog"
      type="Announcement"
      @delete="trash"
      @cancel="deleteDialog=false"
    />
  
  </section>

</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex'
import Toaster from '../../components/reusable/Toaster.vue'
import Header from '../../components/teacher/announcement/Header.vue'
import PostCard from '../../components/teacher/announcement/PostCard.vue'
import PostDialog from '../../components/teacher/announcement/PostDialog.vue'
import PostUpdateDialog from '../../components/teacher/announcement/PostUpdateDialog.vue'

export default {
  components: {
    Header,
    PostCard,
    PostDialog,
    Toaster,
    PostUpdateDialog
  },
  computed: {
   ...mapState({
     user: (state) => state.user
   }),
   ...mapState('instructor', {
    courses: (state) => state.courses,
    announcements: (state) => state.announcements,
    announcement_courses: (state) => {
      let titles = []
      state.announcements.forEach(item =>
        {
          if(item.course !== null){
            titles.push({
              title: item.course.title,
              id: item.course.id
            })
          }
        } 
      )
      return Array.from(new Set(titles))
    }
   }),
  },
  data: () => ({
    post: 'Write Something...',
    dialog: false,
    deleteDialog: false,
    editDialog: false,
    saved: false,
    text: '',
    loading: false,
    filter_loading: false,
    course_loading: false,
    idToDelete: null,
    selected_announcement: null,
    selected_announcement_id: null,
    selected_announcement_comment_id: null,
    selected_course: '',
    selected_date_interval: 'Today',
    endpoint: '/instructor/announcements',
  }),

  mounted() {
    this.course_loading = true

    this.getAllCoursesAction().then(res => {
      this.coursesMutation(res)
    }).finally(() => {
      this.course_loading = false
    })

    if(this.$route.query.course) {
      this.selected_course = this.$route.query.course
    } 
    if (this.$route.query.interval) {
      this.selected_date_interval = this.$route.query.interval
    }
    if(!this.$route.query.course && !this.$route.query.interval) {
      this.$router.replace({ query: { ...this.$route.query, course: this.selected_course, interval: this.selected_date_interval, timestamp: Date.now() } });
    } else if(!this.$route.query.announcement_id && !this.$route.query.announcement_comment_id){
      this.getInstructorAllAnnouncementsAction(`?created_at=${this.selected_date_interval}`).then(res=>{
        this.loading=false
      }).catch(() => {
        this.loading=false
      })
    }
  },

  created() {
    if(!this.$route.query.announcement_id && !this.$route.query.announcement_comment_id){
      this.loading = true
      this.getInstructorAllAnnouncementsAction(`?created_at=${this.selected_date_interval}`).then(res=>{
        this.loading=false
      }).catch(() => {
        this.loading=false
      })
    }
    
  },

  methods: {
    ...mapActions('instructor', ['getInstructorAllAnnouncementsAction', 'getInstructorAnnouncementAction', 'getAllCoursesAction']),

    ...mapMutations('instructor', ['coursesMutation']),

    async posted(e) {
      this.dialog=false
      this.text = e
      this.saved =true
      // this.getInstructorAllAnnouncementsAction(`?created_at=${this.selected_date_interval}`).then(res=>{
      //   this.loading=false
      // }).catch(() => {
      //   this.loading=false
      // })
      this.filterAnnouncements()
    },

    updateAnnouncement(e) {
      this.selected_announcement = e
      this.editDialog = true
    },

    remove(event) {
      this.deleteDialog=true
      this.idToDelete=event
    },

    async trash() {
      await this.$api.delete(`${this.endpoint}/${this.idToDelete}`)
      .then(res => {

        this.deleteDialog=false
        this.filterAnnouncements()
        // this.getInstructorAllAnnouncementsAction(`?created_at=${this.selected_date_interval}`).then(res=>{
        //   this.loading=false
        // }).catch(() => {
        //   this.loading=false
        // })
        this.$store.commit('alertMutation', {
          show: true,
          text: res.data.message,
          type: "success"
        })
      })
    },

    setFilter(course){
      this.$router.replace({ query: { ...this.$route.query, course: course, timestamp: Date.now() } });
      this.selected_course = course
    },

    setDateInterval(interval){
      this.$router.replace({ query: { ...this.$route.query, interval: interval, timestamp: Date.now() } });
      this.selected_date_interval = interval
    },

    setAnnouncement(id) {
      this.$router.replace({ query: { ...this.$route.query, course: this.$route.query.course, interval: this.$route.query.interval, announcement_id: id, timestamp: Date.now() } });
      this.selected_announcement_id = id
    },

    filterAnnouncements(){
      this.filter_loading = true

      let _query = '?'
      if(this.selected_course){
        _query += `course_id=${this.selected_course}&&`
      }
      if(this.selected_date_interval){
        _query += `created_at=${this.selected_date_interval}`
      }
      this.getInstructorAllAnnouncementsAction(_query).then(() => {
        this.filter_loading = false
      }).catch(() => {
        this.filter_loading = false
      })
    },

    getAnnouncement(){
      this.getInstructorAnnouncementAction(this.$route.query.announcement_id).then(() => {
        this.filter_loading = false
        if(this.$route.query.announcement_id && this.$route.query.announcement_comment_id) {
          setTimeout(() => {
            this.$vuetify.goTo(`#comment-${this.$route.query.announcement_comment_id}`, { offset: 50})
          }, 1000)
        }
      }).catch(() => {
        this.filter_loading = false
      })
    }
  },

  watch: {
    selected_course(){
      this.filterAnnouncements()
    },

    selected_date_interval(){
      this.filterAnnouncements()
    },

    '$route.query.timestamp': {
      handler: function() {
        if(this.$route.query.announcement_id && this.$route.query.announcement_comment_id) {
          if(this.selected_announcement_id !== this.$route.query.announcement_id) {
            this.selected_announcement_id = this.$route.query.announcement_id
          } 
          this.selected_announcement_comment_id = this.$route.query.announcement_comment_id

          if(this.selected_announcement_id === this.$route.query.announcement_id && this.$route.query.announcement_comment_id) {
            setTimeout(() => {
              this.$vuetify.goTo(`#comment-${this.$route.query.announcement_comment_id}`, { offset: 50})
            }, 1000)
          } 
        }
      },
      deep: true,
      immediate: true
    },

    selected_announcement_id(val) {
      this.filter_loading = true
      if(val) {
        this.getAnnouncement()
      } else {
        if(this.$route.query.announcement_id) {
          const query = Object.assign({}, this.$route.query);
          delete query.announcement_id;
          this.$router.replace({ query });
        }
        this.filterAnnouncements()
      }
      
    }
  }
}
</script>