var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"max-width":"550","persistent":"","eager":"","retain-focus":"false"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(_vm.announcement)?_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"d-flex align-center justify-space-between"},[_c('h4',{staticClass:"primary--text"},[_vm._v("Update Announcement")]),_c('div',{},[_c('v-btn',{attrs:{"icon":""},on:{"click":()=>{
            this.$refs.form.reset()
            _vm.$emit('close')
          }}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)]),_c('v-card-text',[(_vm.msg.show)?_c('alert',{attrs:{"show":_vm.msg.show,"text":_vm.msg.text,"type":_vm.msg.type}}):_vm._e(),_c('label',{staticClass:"poppins f12 secondary--text mx-1"},[_vm._v("COURSE *")]),_c('v-select',{staticClass:"general-custom-field roboto f14 secondary-1--text fw500 mb-5",attrs:{"items":_vm.courses,"item-value":"id","item-text":"title","outlined":"","dense":"","hide-details":"auto","required":"","disabled":"","rules":_vm.rules},model:{value:(_vm.course_id),callback:function ($$v) {_vm.course_id=$$v},expression:"course_id"}}),_c('label',{staticClass:"poppins f12 secondary--text mx-1"},[_vm._v("CONTENT *")]),(_vm.showMce)?_c('editor',{staticClass:"tiny",attrs:{"api-key":_vm.mce_key,"rules":_vm.rules,"init":{
            height: 200,
            menubar: false,
            plugins: [
              'advlist autolink lists link image charmap print preview anchor table',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar:
              'formatselect | bold italic backcolor | \
              alignleft aligncenter alignright alignjustify | \
              table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol | \
              bullist numlist outdent indent | removeformat | help',
            content_style:
              'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}):_c('circular'),_c('v-alert',{staticClass:"mt-3 f13",attrs:{"value":_vm.alert,"type":"error","transition":"v-scroll-y-transition","dense":"","outlined":""}},[_vm._v(" Announcement content is required ")])],1),_c('v-divider',{}),_c('v-card-actions',{staticClass:"d-flex align-center justify-end"},[_c('v-btn',{staticClass:"text-capitalize poppins secondary-1--text",attrs:{"text":""},on:{"click":()=>{
          this.$refs.form.reset()
          _vm.$emit('close')
        }}},[_vm._v("Cancel")]),_c('v-btn',{staticClass:"text-capitalize poppins",attrs:{"color":"primary","width":"100","loading":_vm.loading},on:{"click":_vm.update}},[_vm._v("Update")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }